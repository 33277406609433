import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Input from '@material-ui/core/Input/Input';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

//This is the actual component that will get rendered in the form

export const ValidateNewProjectName = ({
  onChange,
  rawErrors,
  required,
  data,
}: FieldExtensionComponentProps<string> & { label?: string }) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !data}
    >
      <InputLabel htmlFor="validateName">Project Name</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={(e) => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        Name of your new ADO Project
      </FormHelperText>
    </FormControl>
  );
};


// This is a validation function that will run when the form is submitted.
// You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\

export const ValidateNewProjectNameValidation = async (
  value: string,
  validation: FieldValidation,
) => {
  const ProjectNamePattern = /^([a-zA-Z][a-zA-Z0-9.]*([-_ ]?[a-zA-Z0-9]+)*)*$/;


  if (!ProjectNamePattern.test(value)) {
    validation.addError(
      `Only use letters, numbers, hyphen ("-") and underscore ("_").`,
    );
  }
  const adoOrganisationName = sessionStorage.getItem("orgName");
  try {
    const response = await fetch(
      `https://dev.azure.com/${adoOrganisationName}/_apis/projects/${value}?api-version=6.0`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${btoa(`:75ptpzri6s6pyfxiu6esxhgc3y4454hv4rjko3lkplu7lrncqffa`)}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.ok) {
      validation.addError(`Project with the same name exist in ${adoOrganisationName}`);
      sessionStorage.setItem("projectName", 'null');
    }
    else{
      sessionStorage.setItem("projectName", value);
    }
  } catch (error) {
    validation.addError(`Problem in API call to check project`);
  }
};

