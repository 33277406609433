import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Input from '@material-ui/core/Input/Input';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

//This is the actual component that will get rendered in the form

export const ValidateDeveloperAADGroupName = ({
  onChange,
  rawErrors,
  required,
  data,
}: FieldExtensionComponentProps<string> & { label?: string }) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !data}
    >
      <InputLabel htmlFor="validateName">Developer's AD group Name</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={(e) => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
      Add name of AD Group in which all the developer/users are present which will have access to this new project
      </FormHelperText>
    </FormControl>
  );
};


// This is a validation function that will run when the form is submitted.
// You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\

export const ValidateDeveloperAADGroupNameValidation = async (
  value: string,
  validation: FieldValidation,
) => {
  const ProjectNamePattern = /^([a-zA-Z][a-zA-Z0-9.]*([-_ ]?[a-zA-Z0-9]+)*)*$/;


  if (!ProjectNamePattern.test(value)) {
    validation.addError(
      `Only use letters, numbers, hyphen ("-") and underscore ("_").`,
    );
  }
  const adoOrganisationName = sessionStorage.getItem("orgName");
  let backendUrl = sessionStorage.getItem("backendUrl");
  let requestHeaders = new Headers();  
  requestHeaders.append("orgname", `${adoOrganisationName}`);
  requestHeaders.append("groupname",value);
  try {
    const response = await fetch(backendUrl + "/api/serverside-validation/isaadgroupexist",{ headers: requestHeaders});
    const data = await response.json();
    if (!data.adgroupdetails)
    {
      validation.addError(`AAD group ${value} doen't exist`);
    }
  } catch (error) {
    validation.addError(`Problem in API call to check AAD Group`);
  }
};

