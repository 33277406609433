import React, { useState, useEffect } from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Input from '@material-ui/core/Input/Input';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { useUserProfile } from './useUserProfileInfo';

// This is the actual component that will get rendered in the form
export const ValidateUserEmail = ({
  rawErrors,
  required,
  data,
}: FieldExtensionComponentProps<string> & { label?: string }) => {
  const { profile } = useUserProfile();
  const [email, setEmail] = useState<string>('');
  let useremail: string;
  useEffect(() => {
    const interval = setInterval(() => {
      if (profile.email && profile.email.length > 0) {
        setEmail(profile.email);
        clearInterval(interval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval);
  }, [profile.email]);

  useremail = email;
  if (!email) {
    return null; // or a loading spinner, or any other placeholder
  }

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !data}
    >
      <InputLabel htmlFor="validateName">User Email</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        value={useremail}
      />
      <FormHelperText id="entityName">
        User Email where the link of new resources will be shared
      </FormHelperText>
    </FormControl>
  );
};

// This is a validation function that will run when the form is submitted.
// You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned
export const ValidateUserEmailValidation = async (
  value: string,
  validation: FieldValidation,
) => {
  const UserEmailPattern = /^[a-zA-Z0-9._%+-]+@kcc\.com$/;

  if (!UserEmailPattern.test(value)) {
    validation.addError(
      `Only use value with pattern abc.def@kcc.com is allowed.`,
    );
  }
};