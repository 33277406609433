import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { oktaAuthApiRef } from '@backstage/core-plugin-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import { light, dark } from './theme/amplifi_theme';
import { CssBaseline } from '@material-ui/core';
import { HomePage } from './components/home/HomePage';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { VisitListener } from '@backstage/plugin-home';
import { AutoLogout } from '@backstage/core-components';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { ValidateRepositoryNameFieldExtension,  } from './scaffolder/ValidateRepositoryName/extensions';
import { ValidateProjectNameFieldExtension } from './scaffolder/ValidateProjectName/extensions';
import { ValidateNewProjectNameFieldExtension } from './scaffolder/ValidateNewProjectName/extensions';
import { ValidateDeveloperAADGroupNameFieldExtension } from './scaffolder/ValidateDeveloperAADGroupName/extensions';
import { ValidateApproverAADGroupNameFieldExtension } from './scaffolder/ValidateApproverAADGroupName/extensions';
import { ValidateProjectAdminNameFieldExtension } from './scaffolder/ValidateProjectAdminName/extensions';
import { ValidateExistingRepositoryNameFieldExtension } from './scaffolder/ValidateExistingRepositoryName/extensions';
import { ValidateNewPipelineNameFieldExtension } from './scaffolder/ValidateNewPipelineName/extensions';
import { ValidateUserEmailFieldExtension } from './scaffolder/ValidateUserEmail/extensions'
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        providers={[
          {
            id: 'okta-auth-provider',
            title: 'Login',
            message: 'Sign in using Kimberly-Clark Enterprise Authentication System',
            apiRef: oktaAuthApiRef,
          },
        ]}
        align="center"
      />
    ),
  },

  themes: [
    {
      id: 'light',
      title: 'light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={light} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'dark',
      title: 'dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={dark} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/create" element={<ScaffolderPage />}>
        <ScaffolderFieldExtensions>
          <ValidateRepositoryNameFieldExtension />
          <ValidateProjectNameFieldExtension />
          <ValidateNewProjectNameFieldExtension />
          <ValidateDeveloperAADGroupNameFieldExtension />
          <ValidateApproverAADGroupNameFieldExtension />
          <ValidateProjectAdminNameFieldExtension />
          <ValidateExistingRepositoryNameFieldExtension />
          <ValidateNewPipelineNameFieldExtension />
          <ValidateUserEmailFieldExtension />
        </ScaffolderFieldExtensions>
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    {/* ... */}
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <AutoLogout
      idleTimeoutMinutes={120}
      useWorkerTimers={false}
      logoutIfDisconnected={false}
    />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);