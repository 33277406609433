import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

// List of disallowed words
const disallowedWords = ['AUX', 'COM1', 'CON', 'COM2', 'COM3', 'COM4', 'COM5', 'COM6', 'COM7', 'COM8', 'COM9', 'COM10', 'DefaultCollection', 'LPT1', 'LPT2', 'LPT3', 'LPT4', 'LPT5', 'LPT6', 'LPT7', 'LPT8', 'LPT9', 'NUL', 'PRN', 'SERVER', 'SignalR', 'Web', 'WEB'];

export const ValidateRepositoryName = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel htmlFor="validateName">Repository Name</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={(e) => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        Name of your new repository (do not use any system reserved name as listed in this <a href="https://learn.microsoft.com/en-us/azure/devops/organizations/settings/naming-restrictions?view=azure-devops#system-reserved-names" target="_blank" rel="noopener noreferrer"><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>document</span></a>).
      </FormHelperText>
    </FormControl>
  );
};

export const ValidateRepositoryNameValidation = async (
  value: string,
  validation: FieldValidation,
) => {
  const repositoryNamePattern = /^([a-zA-Z][a-zA-Z0-9]*)([-_][a-zA-Z0-9]+)*$/;

  if (!repositoryNamePattern.test(value)) {
    validation.addError(
      ' Only use letters, numbers, hyphen ("-"), and underscore ("_").',
    );
  }

  // Check if the input matches any disallowed words
  const containsDisallowedWord = disallowedWords.some(word =>
    new RegExp(`\\b${word}\\b`, 'i').test(value)
  );

  if (containsDisallowedWord) {
    validation.addError('Repository name should not be system reserved name please use another name');
  }

  const adoOrganisationName = sessionStorage.getItem("orgName");
  const adoprojectName = sessionStorage.getItem("projectName");
  try {
    const response = await fetch(
      `https://dev.azure.com/${adoOrganisationName}/${adoprojectName}/_apis/git/repositories/${value}?api-version=5.1`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Basic ${btoa(`:75ptpzri6s6pyfxiu6esxhgc3y4454hv4rjko3lkplu7lrncqffa`)}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.ok) {
      validation.addError(`A Repository with the same name already exist in ADO Project: ${adoprojectName} please enter a different name.`);
    }

  } catch (error) {
    validation.addError(`Problem in API call to check project`);
  }

};
