import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

export const ValidateExistingRepositoryName = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel htmlFor="validateName">Repository Name</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={(e) => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        Name of your repository where source code exist.
      </FormHelperText>
    </FormControl>
  );
};

export const ValidateExistingRepositoryNameValidation = async (
  value: string,
  validation: FieldValidation,
) => {
  let backendUrl = sessionStorage.getItem("backendUrl");
  const adoOrganisationName = sessionStorage.getItem("orgName");
  const adoprojectName = sessionStorage.getItem("projectName");
  let requestHeaders = new Headers();  
  requestHeaders.append("orgname", `${adoOrganisationName}`);
  requestHeaders.append("projectname", `${adoprojectName}`);
  requestHeaders.append("reponame",`${value}`);
  try {
    const response = await fetch(backendUrl + "/api/serverside-validation/isrepoexist",{ headers: requestHeaders});
    const isrepoexistdetails = await response.json();
    if (!isrepoexistdetails.repodetails) {
      validation.addError(`Repository doesn't exist in ADO Project: ${adoprojectName} please enter a different name.`);
    }

  } catch (error) {
    validation.addError(`Problem in API call to check repository name`);
  }

};
