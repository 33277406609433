import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Input from '@material-ui/core/Input/Input';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

//This is the actual component that will get rendered in the form

export const ValidateNewPipelineName = ({
  onChange,
  rawErrors,
  required,
  data,
}: FieldExtensionComponentProps<string> & { label?: string }) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !data}
    >
      <InputLabel htmlFor="validateName">Pipeline Name</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={(e) => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        Name of your new DevOps Pipeline
      </FormHelperText>
    </FormControl>
  );
};


// This is a validation function that will run when the form is submitted.
// You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\

export const ValidateNewPipelineNameValidation = async (
  value: string,
  validation: FieldValidation,
) => {
  const ProjectNamePattern = /^([a-zA-Z][a-zA-Z0-9.]*([-_ ]?[a-zA-Z0-9]+)*)*$/;
  let backendUrl = sessionStorage.getItem("backendUrl");
  const adoOrganisationName = sessionStorage.getItem("orgName");
  const adoprojectName = sessionStorage.getItem("projectName");
  let requestHeaders = new Headers();  
  requestHeaders.append("orgname", `${adoOrganisationName}`);
  requestHeaders.append("projectname", `${adoprojectName}`);

  if (!ProjectNamePattern.test(value)) {
    validation.addError(
      `Only use letters, numbers, hyphen ("-") and underscore ("_").`,
    );
  }
  
  try {
    const response = await fetch(backendUrl + "/api/serverside-validation/ispipelineexist",{ headers: requestHeaders});
    const ispipelineexist = await response.json();
    
    const existingPipelines= ispipelineexist.pipelinedetails.value.map((pipeline: any) => pipeline.name);
    
    if (existingPipelines.includes(value)) {
      validation.addError(`Pipeline with the same name exist in ${adoprojectName} please choice a different name`);
    }
  } catch (error) {
    validation.addError(`Problem in API call to check project`);
  }
};

