import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Input from '@material-ui/core/Input/Input';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';

//This is the actual component that will get rendered in the form

export const ValidateProjectAdminName = ({
  onChange,
  rawErrors,
  required,
  data,
}: FieldExtensionComponentProps<string> & { label?: string }) => {
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !data}
    >
      <InputLabel htmlFor="validateName">Project Administrators</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={(e) => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
      Add emails of users which will be added as project adminstrator in this new project (add as , seperated values for example abc@kcc.com,def@kcc.com)
      </FormHelperText>
    </FormControl>
  );
};


// This is a validation function that will run when the form is submitted.
// You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\

export const ValidateProjectAdminNameValidation = async (
  value: string,
  validation: FieldValidation,
) => {
  const ProjectNamePattern = /^([a-zA-Z0-9._%+-]+@kcc\.com)(,[a-zA-Z0-9._%+-]+@kcc\.com)*$/;


  if (!ProjectNamePattern.test(value)) {
    validation.addError(
      `add as ',' seperated values for example abc@kcc.com,def@kcc.com`,
    );
  }
};

