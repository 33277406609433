import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Input from '@material-ui/core/Input/Input';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
//This is the actual component that will get rendered in the form

export const ValidateProjectName = ({
  onChange,
  rawErrors,
  required,
  data,
}: FieldExtensionComponentProps<string> & { label?: string }) => {
  const  config = useApi(configApiRef);
  const backendUrl =  config.getString('backend.baseUrl');
  sessionStorage.setItem("backendUrl",`${backendUrl}`);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !data}
    >
      <InputLabel htmlFor="validateName">ADO Project</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={(e) => onChange(e.target?.value)}
      />
      <FormHelperText id="entityName">
        Name of your ADO Project
      </FormHelperText>
    </FormControl>
  );
};


// This is a validation function that will run when the form is submitted.
// You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\

export const ValidateProjectNameValidation = async (
  value: string,
  validation: FieldValidation,
) => {
  const ProjectNamePattern = /^([a-zA-Z][a-zA-Z0-9.]*([-_ ]?[a-zA-Z0-9]+)*)*$/;
  let backendUrl = sessionStorage.getItem("backendUrl");

  if (!ProjectNamePattern.test(value)) {
    validation.addError(
      `Only use letters, numbers, hyphen ("-") and underscore ("_").`,
    );
  }
  let projectexist = false;
  let names: string[] = ['KC-DataAnalytics','apiatkc','KC-DigitalInnovation','kccapiplatform','kccsalesforce','kc-gmsi','kc-uc','muleapi','KC-PowerBI','k-c','kccloudhosting','PaloAltoOps','KC-DigitalRnD','kc-sales-mobile','kc-ux','kc-digitalmarketing','bigdata-kcc'];
  
  for (let i = 0; i < names.length; i++) 
  {
    let requestHeaders = new Headers();
    
    requestHeaders.append("orgname", `${names[i]}`);
    requestHeaders.append("projectname", `${value}`);
    try {
      const response = await fetch(backendUrl + "/api/serverside-validation/isprojectexist",{ headers: requestHeaders});
      const isprojectexistdetails = await response.json();
      if (!isprojectexistdetails.projectdetails)
      {
        projectexist = false;
      }
      else
      {
        projectexist = true;
        sessionStorage.setItem("orgName",`${names[i]}`);
        break;
      }
      
    } catch (error) {
      validation.addError(`Problem in API call to check project`);
    }
  }
  if (!projectexist) {
    validation.addError(`This ADO project doesn't exist`);
    sessionStorage.setItem("projectName", 'null');
  }
  else{
    sessionStorage.setItem("projectName", value);
  }
  
};

